import React, { useState, useContext } from "react";
import GoogleLogo from "../../assets/GoogleLogo";
import MicrosoftLogo from "../../assets/MicrosoftLogo";

// Contexts
import { AuthContext } from "../../auth/firebase/AuthContext";

const AuthenticationPage = ({ schema }) => {
  const { authenticateWithEmailPassword, authenticateWithGoogle } =
    useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await authenticateWithEmailPassword(email, password);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex flex-col w-full bg-zinc-100">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen w-full gap-y-8">
        <div className="w-full bg-white rounded-xl md:mt-0 sm:max-w-md xl:p-0 border-2">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <img
              src={schema?.business_logo_url}
              className="w-24 flex justify-self-center"
              alt={schema?.business_name}
            />
            <h1 className="text-xl font-bold leading-tight tracking-tight text-zinc-900 md:text-2xl">
              Login to {schema?.business_name}
            </h1>
            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-zinc-900"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`bg-zinc-50 border text-zinc-900 rounded-lg focus:ring-zinc-600 focus:border-zinc-600 block w-full p-2.5 ${
                    error ? "border-red-500" : "border-zinc-300"
                  }`}
                  placeholder="name@company.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-zinc-900"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className={`bg-zinc-50 border text-zinc-900 rounded-lg focus:ring-zinc-600 focus:border-zinc-600 block w-full p-2.5 ${
                    error ? "border-red-500" : "border-zinc-300"
                  }`}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && <div className="text-red-500 text-sm">{error}</div>}
              <button
                type="submit"
                className="w-full text-white bg-zinc-900 hover:bg-zinc-700 focus:ring-4 focus:outline-none focus:ring-zinc-300 font-medium rounded-lg text-md px-5 py-2.5 text-center"
              >
                Login
              </button>
            </form>
            <div className="flex items-center justify-center">
              <a
                className="text-sm font-medium text-zinc-600 hover:underline"
                href="/"
              >
                Forgot password?
              </a>
            </div>
            <button
              onClick={() => console.log("handle microsoft auth")}
              className="w-full text-zinc-900 bg-white border hover:bg-zinc-200 focus:ring-4 focus:outline-none focus:ring-zinc-100 font-medium rounded-lg text-md px-5 py-2.5 text-center flex gap-x-3 justify-center items-center"
            >
              <MicrosoftLogo size={20} />
              Login with Microsoft
            </button>
            <button
              onClick={authenticateWithGoogle}
              className="w-full text-zinc-900 bg-white border hover:bg-zinc-200 focus:ring-4 focus:outline-none focus:ring-zinc-100 font-medium rounded-lg text-md px-5 py-2.5 text-center flex gap-x-3 justify-center items-center"
            >
              <GoogleLogo size={20} />
              Login with Google
            </button>
          </div>
        </div>
        <a
          className="text-zinc-300 flex items-center"
          href="https://runtalos.com"
          target="_blank"
          rel="noreferrer"
        >
          Powered by Talos
        </a>
      </div>
    </div>
  );
};

export default AuthenticationPage;
